/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { useLayout } from "../../../core";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  menuTrigger?: 'click' | `{default:'click', lg: 'hover'}`
  menuPlacement?: 'right-start' | 'bottom-start' | 'left-start'
  hasArrow?: boolean
  hasBullet?: boolean
  isMega?: boolean
}

const MegaDash: FC<Props & WithChildren>  = (
  {children,
    to,
    title,
    icon,
    fontIcon,
    menuTrigger,
    menuPlacement,
    hasArrow = false,
    hasBullet = false,
    isMega = false,
  }
) => {
  const { setLayoutType, setToolbarType } = useLayout();
  const {pathname} = useLocation()
  const menuItemRef = useRef<HTMLDivElement>(null)
  const { permissionVideos, permissionSeries, permissionLiveTV, permissionLibrary, permissionEncoding, permissionVideoLayout, 
          permissionCustomerAnalystics, permissionGeoFencing,
          permissionDeviceAnalystics, permissionGeoLocationAnalystics, permissionRealTimeAnalystics,
          permissionRevenueAnalystics, permissionContentAnalystics } = usePermissionValues();

  const Permission_Analytics =  permissionCustomerAnalystics.isViewable || permissionContentAnalystics.isViewable ||
                                permissionDeviceAnalystics.isViewable || permissionGeoLocationAnalystics.isViewable || 
                                permissionRealTimeAnalystics.isViewable || permissionRevenueAnalystics.isViewable;

  useEffect(() => {
    if (menuItemRef.current && menuTrigger && menuPlacement) {
      menuItemRef.current.setAttribute('data-kt-menu-trigger', menuTrigger)
      menuItemRef.current.setAttribute('data-kt-menu-placement', menuPlacement)
    }
  }, [menuTrigger, menuPlacement])

  return (
  <div
    className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible user-select-none"
    data-kt-menu-dismiss="true"
  >
    <div className="row">
      <div className={`${Permission_Analytics ? `col-lg-8`: `col-lg-12`} mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6`}>
        <div className="row">
          {(permissionVideos.isViewable) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/video/entries"
                className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/video/entries'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                <i className="ki-duotone ki-youtube fs-1 text-blue-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                 
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Video</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Upload Videos
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionSeries.isViewable) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/playlist/series"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/playlist/series'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-abstract-26 text-danger fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Series</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Manage Series
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionLiveTV.isViewable) && 
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/livestream/entries"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/livestream/entries'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-satellite text-info fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path2"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Live TV</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Broadcast Settings
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
          {(permissionLibrary.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/library/entries"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/library/entries'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-color-swatch text-warning fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                    <span className="path15"></span>
                    <span className="path16"></span>
                    <span className="path17"></span>
                    <span className="path18"></span>
                    <span className="path19"></span>
                    <span className="path20"></span>
                    <span className="path21"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">
                  Library
                  </span>
                  <span className="fs-7 fw-semibold text-muted">
                  Content Vault
                  </span>
                </span>
              </Link>
            </div>
          </div>
  }
  {(permissionEncoding.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/encoding/profile"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/encoding/profile'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-code text-danger fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Encoding</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Optimize Streams
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
{(permissionVideoLayout.isViewable) &&
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <Link
                to="/content/layout/general-layout"
                 className={clsx('menu-link ', {
                  active: checkIsActive(pathname, '/content/layout/general-layout'),
                })}
              >
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-switch text-blue-primary fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Layout</span>
                  <span className="fs-7 fw-semibold text-muted">
                  Customize View
                  </span>
                </span>
              </Link>
            </div>
          </div>
}
        
        </div>

      {(permissionGeoFencing.isViewable) && (
        <>
        <div className="separator separator-dashed mx-5 my-5"></div>
        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
          <div className="d-flex flex-column me-5">
            <div className="fs-6 fw-bold text-gray-800">
            App Settings
            </div>
            <div className="fs-7 fw-semibold text-muted">
            Manage Geolocation Settings with Ease
            </div>
          </div>
          <Link
            to="/manage/geofencing"
            className="btn btn-sm btn-primary fw-bold"
            style={{backgroundColor:'#3699ff', color:'#fff'}}
          >
            Explore
          </Link>
        </div>
        </>)}

      </div>

      {(Permission_Analytics) && (
      <div className="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end">
        <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
          Analytics
        </h4>

        {(permissionContentAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/analytics-content"
            className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/analytics-content'),
            })}
          >
            <span className="menu-title">Content Analytics</span>
          </Link>
        </div>}

        {(permissionCustomerAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/audience"
             className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/audience'),
            })}
          >
            <span className="menu-title">Customer Analytics</span>
          </Link>
        </div>}

        {(permissionDeviceAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/analytics-devices"
             className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/analytics-devices'),
            })}
          >
            <span className="menu-title">Devices</span>
          </Link>
        </div>}

        {(permissionGeoLocationAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/analytics-geolocation"
             className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/analytics-geolocation'),
            })}
          >
            <span className="menu-title">Geo Location</span>
          </Link>
        </div>}


        {(permissionRealTimeAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/analytics-realtime"
             className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/analytics-realtime'),
            })}
          >
            <span className="menu-title">Real Time</span>
          </Link>
        </div>}

        {(permissionRevenueAnalystics.isViewable) &&
        <div className="menu-item p-0 m-0">
          <Link
            to="/analytics/analytics-revenue"
             className={clsx('menu-link py-2', {
              active: checkIsActive(pathname, '/analytics/analytics-revenue'),
            })}
          >
            <span className="menu-title">Revenue</span>
          </Link>
        </div>}

      </div>
      )}
    </div>
    </div>
  );
};

export { MegaDash };
