import { useAuth } from "../modules/auth";
import { useState, useEffect } from 'react';

function useCheckPermission(permissionVal) {
  const { currentUser } = useAuth();
  const [permission, setPermission] = useState({
    isViewable: false,
    isCreatable: false,
    isEditable: false,
    isDeletable: false,
  });

  useEffect(() => {
    const localStorageValue = JSON.parse(window.localStorage.getItem('permission'));

    // console.log("Permission value:", localStorageValue);

    if (localStorageValue?.length > 0) {
      const permissions = localStorageValue
        .filter((val) => val?.module?.toLowerCase() === permissionVal.toLowerCase())
        .map((val) => val.type.toLowerCase());

      setPermission({
        isViewable: permissions.includes('view'),
        isCreatable: permissions.includes('create'),
        isEditable: permissions.includes('edit'),
        isDeletable: permissions.includes('delete'),
      });
    } else if (currentUser?.userType === 'SUPERADMIN') {
      setPermission({
        isViewable: true,
        isCreatable: true,
        isEditable: true,
        isDeletable: true,
      });
    }
    else 
    setPermission({
      isViewable: false,
      isCreatable: false,
      isEditable: false,
      isDeletable: false,
    });
  }, [permissionVal, currentUser]);

  // useEffect(()=>{
  //   setPermission({
  //     isViewable: true,
  //     isCreatable: true,
  //     isEditable: true,
  //     isDeletable: true,
  //   });

  // },[permissionVal])

  return permission;
}

export default useCheckPermission;

export function usePermissionValues() {
  const permissionVideos = useCheckPermission("Videos");
  const permissionSeries = useCheckPermission("Series");
  const permissionSeasons = useCheckPermission("Seasons");
  const permissionEpisodes = useCheckPermission("Episodes");
  const permissionLiveTV = useCheckPermission("Live TV");
  const permissionLibrary = useCheckPermission("Library");
  const permissionEncoding = useCheckPermission("Encoding");
  const permissionVideoLayout = useCheckPermission("Layouts");


  const permissionAlbums = useCheckPermission("Albums");
  const permissionPodcasts = useCheckPermission("Podcasts");
  const permissionPlayist = useCheckPermission("Playlist");
  const permissionAudioGenres = useCheckPermission("Audio Genres");
  const permissionAudioLayout = useCheckPermission("Audio Layouts");

  const permissionCustomerAnalystics = useCheckPermission("Customer Analytics");
  const permissionContentAnalystics = useCheckPermission("Content Analytics");
  const permissionAudioAnalystics = useCheckPermission("Audio Analytics");
  const permissionDeviceAnalystics = useCheckPermission("Device Analytics");
  const permissionGeoLocationAnalystics = useCheckPermission("Geo Location Analytics");
  const permissionRealTimeAnalystics = useCheckPermission("Real Time Analytics");
  const permissionRevenueAnalystics = useCheckPermission("Revenue Analytics");


  const permissionCategories = useCheckPermission("Categories");
  const permissionLanguage = useCheckPermission("Language");
  const permissionGenres = useCheckPermission("Genres");
  const permissionProdHouse = useCheckPermission("Prod House");
  const permissionCasts = useCheckPermission("Casts");
  const permissionGeoRestriction = useCheckPermission("Geo Restrictions");
  const permissionContentPartner = useCheckPermission("Content Partner");


  const permissionAdminUsers = useCheckPermission("Admin Users");
  const permissionRoles = useCheckPermission("Roles");

  const permissionBroadcastGroups = useCheckPermission("Broadcast Groups");
  const permissionBroadcastMessages = useCheckPermission("Broadcast Messages");

  const permissionPlans = useCheckPermission("Plans");
  const permissionTransaction = useCheckPermission("Transaction");

  const permissionFeedbacks = useCheckPermission("Feedbacks");

  const permissionDashboard = useCheckPermission("Dashboard");

  const permissionGeoFencing = useCheckPermission("Geo Fencing");

  const permissionUserManagement = useCheckPermission("User Management");



  return {
    permissionVideos, permissionSeries, permissionSeasons, permissionEpisodes, 
    permissionLiveTV, permissionLibrary, permissionEncoding, permissionVideoLayout,

    permissionAlbums, permissionPodcasts, permissionPlayist, permissionAudioGenres, permissionAudioLayout,

    permissionCategories, permissionLanguage, permissionGenres, permissionProdHouse, 
    permissionCasts, permissionGeoRestriction, permissionContentPartner,

    permissionCustomerAnalystics, permissionContentAnalystics, permissionAudioAnalystics, permissionDeviceAnalystics, 
    permissionGeoLocationAnalystics, permissionRealTimeAnalystics, permissionRevenueAnalystics,

    permissionPlans, permissionTransaction, permissionDashboard, permissionGeoFencing, permissionUserManagement,
    
    permissionAdminUsers, permissionRoles, permissionBroadcastGroups, permissionBroadcastMessages, permissionFeedbacks,
  };
}

